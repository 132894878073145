<template>
  <!-- { "id": "6149da8ba090df0026417dad", "body": "Sample Comment to the comment 1", "author": "kannanm", "author_profile_image_url": "", "author_role": "Learner", "created_at": "2021-09-21T13:13:47Z", "updated_at": "2021-09-21T13:13:47Z" } -->
  <div class="comment__box--inner">
    <div class="box-header">
      <div class="header-left">
        <div class="display-name">
          <div class="display-image">
            <b-img
              :src="authorProfileImagePath(comment.author_profile_image_url)"
              alt="avatar"
            ></b-img>
          </div>
          <div class="box-author">
            {{ comment.author }}
          </div>
          <div class="box-date">
            {{ comment?.updated_at | humanizeNew }}
          </div>
        </div>
      </div>
      <!-- <div class="header-right"></div> -->
    </div>
    <div class="box-content">
      <div class="view-response" ref="viewComment">
        {{ injectIntoDiv(commentBody) }}
      </div>
      <ImageButton
        v-if="!showFullContent && textIsTruncated"
        :defaultIcon="viewMoreIcon"
        :pressedIcon="viewMorePressedIcon"
        altText="View More"
        @icon-click="handleIconClick"
        class="view-more"
      />
    </div>
  </div>
</template>
<script>
import authorProfileImagePath from "@/core/mixins/authorProfileImagePath.js";
const MAX_TRUNCATED_HEIGHT = 72;
export default {
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  mixins: [authorProfileImagePath],
  data() {
    return {
      viewMoreIcon: require("@/assets/images/vle/forums/response/view-more.svg"),
      viewMorePressedIcon: require("@/assets/images/vle/forums/response/view-more-pressed.svg"),
      commentBody: "",
      showFullContent: false,
      observer: null,
      textIsTruncated: false
    };
  },
  mounted() {
    this.commentBody = this.comment.body;
    this.setupResizeObserver();
  },
  beforeUnmount() {
    this.observer?.disconnect?.();
  },
  methods: {
    setupResizeObserver() {
      this.observer = new ResizeObserver(entries => {
        const readOnlyResponse = entries[0];
        const height = readOnlyResponse.contentRect.height;
        if (this.showFullContent) {
          this.$refs.viewComment.classList.remove("line-clamp", "clamp3");
          this.textIsTruncated = false;
          return;
        }
        if (height === 0) {
          this.textIsTruncated = false;
          return;
        }
        if (height > MAX_TRUNCATED_HEIGHT) {
          this.$refs.viewComment.classList.add("line-clamp", "clamp3");
          this.textIsTruncated = true;
          return;
        }
      });
      const readOnlyResponseElement = this.$refs.viewComment;
      this.observer.observe(readOnlyResponseElement);
    },
    injectIntoDiv(data) {
      const element = this.$refs.viewComment;
      if (element) {
        element.innerHTML = data;
      }
    },
    handleIconClick() {
      if (!this.showFullContent) {
        this.showFullContent = true;
        this.$refs.viewComment.classList.remove("line-clamp", "clamp3");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.comment__box--inner {
  display: flex;
  flex-direction: column;
  background-color: $brand-neutral-0;
  &:not(:first-child) {
    margin-top: 40px;
  }
  .box-header {
    @include horizontal-space-between;
    .display-name {
      @include flex-center;
      .display-image {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        @include flex-horizontal-center;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .box-author {
      margin-left: 8px;
      @include label-large($brand-neutral-700, 500);
    }
    .box-date {
      padding-left: 24px;
      @include label-large($brand-neutral-300, 500);
      position: relative;
      &:before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $brand-neutral-700;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
      }
    }
  }
  .box-content {
    margin-top: 8px;
    @include body-regular;
    word-break: break-word;
    position: relative;
    .view-response {
      &.line-clamp {
        padding-right: 32px;
      }
    }
    .view-more {
      position: absolute;
      bottom: -3px;
      right: 0px;
    }
  }
}
</style>
